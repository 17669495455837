import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { map } from 'lodash';
import useStyles from './Styles.js';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
  Collapse,
  CircularProgress,
} from '@mui/material';
import Gravatar from 'react-gravatar';
import Dashboard from '@mui/icons-material/Dashboard';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ViewList from '@mui/icons-material/ViewList';
import AddBox from '@mui/icons-material/AddBox';
import Assignment from '@mui/icons-material/Assignment';
import SearchSharp from '@mui/icons-material/SearchSharp';
import LookupOneIcon from '@mui/icons-material/LooksOneOutlined';
import RepeatSharp from '@mui/icons-material/RepeatSharp';
import InvIcon from '@mui/icons-material/HomeWork';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Fingerprint from '@mui/icons-material/Fingerprint';
import MapIcon from '@mui/icons-material/Map';
import PlacesIcon from '@mui/icons-material/Public';
import ExploreIcon from '@mui/icons-material/Explore';
import WarningIcon from '@mui/icons-material/WarningAmber';

import { useAuth0 } from '../../services/user';

const FlashingWarningIcon = () => (
  <ListItemIcon>
    <WarningIcon
      sx={{
        '@keyframes pulse': {
          '0%': {
            transform: 'scale(1, 1)',
          },
          '25%': {
            color: 'black',
          },
          '50%': {
            opacity: 0.9,
          },
          '75%': {
            color: 'red',
          },
          '100%': {
            transform: 'scale(1.75)',
            opacity: 0.4,
          },
        },
        animation: 'pulse 1.5s linear infinite',
      }}
    />
  </ListItemIcon>
);

const Nav = ({ isOpen, hasUpdate, onUpdateClick }) => {
  const classes = useStyles();
  const location = useLocation();
  let expanded = 'Experiments';
  if (location.pathname) {
    if (/^\/inventory/.test(location.pathname)) {
      expanded = 'Inventory';
    } else if (/^\/places/.test(location.pathname)) {
      expanded = 'Places';
    }
  }
  const { user, loading, isAuthenticated, logout, loginWithPopup } = useAuth0();

  const navItems = [
    {
      label: 'Terria Map',
      icon: <MapIcon />,
      url: '/map',
      display: true,
    },
    {
      label: 'My Dashboard',
      icon: <Dashboard />,
      url: '/dashboard',
      display: isAuthenticated,
    },
    {
      label: 'Experiments',
      icon: <Assignment />,
      display: isAuthenticated,
      children: [
        {
          display: isAuthenticated,
          label: 'List',
          icon: <ViewList />,
          url: '/projects',
        },
        {
          display: isAuthenticated,
          label: 'Create Project',
          icon: <AddBox />,
          url: '/projects/create',
        },
      ],
    },
    {
      label: 'Inventory',
      icon: <InvIcon />,
      display: isAuthenticated,
      children: [
        {
          display: isAuthenticated,
          label: 'Search',
          icon: <SearchSharp />,
          url: '/inventory/search',
        },
        {
          display: isAuthenticated,
          label: 'Move',
          icon: <RepeatSharp />,
          url: '/inventory/move',
        },
        {
          display: isAuthenticated,
          label: 'Create',
          icon: <AddBox />,
          url: '/inventory/create',
        },
      ],
    },
    {
      label: 'Places',
      icon: <PlacesIcon />,
      display: isAuthenticated,
      children: [
        {
          display: isAuthenticated,
          label: 'Explore',
          icon: <ExploreIcon />,
          url: '/places/explore',
        },
        {
          display: isAuthenticated,
          label: 'Upload',
          icon: <AddBox />,
          url: '/places/upload',
        },
      ],
    },
    {
      display: isAuthenticated,
      label: 'ID Lookup',
      icon: <LookupOneIcon />,
      url: '/lookup',
    },
    { type: 'divider' },
    {
      label: 'Sign Out',
      icon: <ExitToApp />,
      display: isAuthenticated,
      onClick: logout,
    },
    {
      label: 'Sign In',
      icon: <Fingerprint />,
      display: !isAuthenticated,
      onClick: () => {
        loginWithPopup();
      },
    },
  ];

  let header = <div className={classes.drawerHeader} />;

  if (loading) {
    header = (
      <div className={classes.drawerHeader}>
        <CircularProgress />
      </div>
    );
  } else if (user) {
    header = (
      <div className={classes.drawerHeader}>
        <Avatar style={{ marginRight: 10 }}>
          <Gravatar
            email={user && user.email}
            rating="pg"
            default="identicon"
          />
        </Avatar>
        <Typography variant="subtitle2" style={{ color: 'white' }}>
          {user.name}
        </Typography>
      </div>
    );
  }

  return (
    <nav>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={isOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div>
          {header}
          <Divider />
          <List>
            {map(navItems, (item, index) => {
              return (
                <DisplayNavItem
                  key={index}
                  {...item}
                  expanded={item.label === expanded}
                />
              );
            })}
            <Divider />
            {hasUpdate && (
              <ListItem button onClick={onUpdateClick}>
                <FlashingWarningIcon />
                <ListItemText
                  primary="Update"
                  primaryTypographyProps={{ sx: { fontWeight: 'bold' } }}
                />
              </ListItem>
            )}
            <Link className={classes.link} to="/help">
              <ListItem button>
                <ListItemText primary="Help" />
              </ListItem>
            </Link>
            <Link className={classes.link} to="/_info">
              <ListItem button>
                <ListItemText primary="About" />
              </ListItem>
            </Link>
          </List>
        </div>
      </Drawer>
    </nav>
  );
};

function DisplayNavItem(props) {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(props.expanded);
  useEffect(() => {
    setIsExpanded(props.expanded);
  }, [props.expanded]);

  if (props.type === 'divider') {
    return <Divider />;
  }

  if (!props.display) {
    return null;
  }

  switch (true) {
    case !!props.url && !!props.children:
      return (
        <>
          <ListItem button onClick={() => setIsExpanded(!isExpanded)}>
            <ListItemIcon>{props.icon}</ListItemIcon>
            <ListItemText primary={props.label} />
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <List className={classes.subList}>
              {map(props.children, (child, index) => {
                return <DisplayNavItem key={index} {...child} />;
              })}
            </List>
          </Collapse>
        </>
      );
    case !props.url && !!props.children:
      return (
        <>
          <ListItem button onClick={() => setIsExpanded(!isExpanded)}>
            <ListItemIcon>{props.icon}</ListItemIcon>
            <ListItemText primary={props.label} />
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <List className={classes.subList}>
              {map(props.children, (child, index) => {
                return <DisplayNavItem key={index} {...child} />;
              })}
            </List>
          </Collapse>
        </>
      );
    case !!props.url && !props.children:
      return (
        <Link className={classes.link} to={props.url}>
          <ListItem button>
            <ListItemIcon>{props.icon}</ListItemIcon>
            <ListItemText primary={props.label} />
          </ListItem>
        </Link>
      );
    default:
      return (
        <ListItem button onClick={props.onClick}>
          <ListItemIcon>{props.icon}</ListItemIcon>
          <ListItemText primary={props.label} />
        </ListItem>
      );
  }
}

Nav.propTypes = {
  isOpen: PropTypes.bool,
  hasUpdate: PropTypes.bool,
  onUpdateClick: PropTypes.func.isRequired,
};

export default Nav;
