import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import { map, isArray } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
}));

/**
 * Flexible error message printer.
 * @param error - May be singular or an array. May be strings and/or Error objects.
 **/
const Error = ({ error }) => {
  const classes = useStyles();
  let errList = isArray(error) ? error : [error];
  return (
    <Paper className={classes.root} elevation={0}>
      <Typography color="error" variant="h6">
        Error
      </Typography>
      <List>
        {map(errList, (err, index) => {
          if (!err) {
            return null;
          }
          return (
            <ListItem key={index} className={classes.listItem}>
              <ListItemText primary={err.message ? err.message : err} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};

export default Error;
