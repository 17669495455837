import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { useClearCache } from 'react-clear-cache';

import { Nav, UpdateReload } from './Components/Nav';
import AppBar from './Components/AppBar/Index';
import asyncComponent from './HOC/AsyncComponent';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import ProtectedRoute from './HOC/ProtectedRoute';

import 'leaflet/dist/leaflet.css';

const AppInfo = asyncComponent(() => import('./Components/AppInfo/AppInfo'));
const AsyncHome = asyncComponent(() => import('./Components/Home/Home'));
const AsyncDashboard = asyncComponent(() =>
  import('./Serenity/Projects/UserProjects'),
);
const AsyncHelp = asyncComponent(() => import('./UI/UserDocs/RoutedHelp'));
const AsyncMap = asyncComponent(() => import('./Components/Map/Map'));
const AsyncExperiment = asyncComponent(() =>
  import('./Serenity/Experiments/Router'),
);
const AsyncProjects = asyncComponent(() =>
  import('./Serenity/Projects/Router'),
);
const AsyncInventory = asyncComponent(() => import('./Collectid/Router'));
const AsyncPlaces = asyncComponent(() => import('./Placid/Router'));
const AsyncLookup = asyncComponent(() => import('./Components/Lookup'));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: '240px ',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

const App = () => {
  const [navOpen, setNavOpen] = useState(true);
  const [updatesDismissed, setDismissUpdate] = useState(false);
  const classes = useStyles();
  const { isLatestVersion } = useClearCache();

  return (
    <Router>
      <AppBar open={navOpen} handleDrawerToggle={() => setNavOpen(!navOpen)} />
      <Nav
        isOpen={navOpen}
        hasUpdate={!isLatestVersion}
        onUpdateClick={() => setDismissUpdate(false)}
      />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: !navOpen,
        })}
      >
        <div className={classes.drawerHeader} />
        {!isLatestVersion && !updatesDismissed && (
          <UpdateReload onDismiss={() => setDismissUpdate(true)} />
        )}
        <Routes>
          <Route path="/_info" element={<AppInfo />} />
          <Route path="/" element={<AsyncHome />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute path={'dashboard'} component={AsyncDashboard} />
            }
          />
          <Route path="/map" element={<AsyncMap />} />
          <Route path="/help/*" element={<AsyncHelp />} />
          <Route
            path="/experiments/*"
            element={
              <ProtectedRoute
                path={'experiments'}
                component={AsyncExperiment}
              />
            }
          />
          <Route
            path="/projects/*"
            element={
              <ProtectedRoute path={'projects'} component={AsyncProjects} />
            }
          />
          <Route
            path="/inventory/*"
            element={
              <ProtectedRoute path={'inventory'} component={AsyncInventory} />
            }
          />
          <Route
            path="/places/*"
            element={<ProtectedRoute path={'places'} component={AsyncPlaces} />}
          />
          <Route
            path="/lookup"
            element={<ProtectedRoute path={'lookup'} component={AsyncLookup} />}
          />
          <Route
            path="*"
            element={<Navigate to="/dashboard" replace={true} />}
          />
        </Routes>
      </main>
    </Router>
  );
};

export default App;
