import React, { useState, useEffect } from 'react';
import { useAuth0 } from '../services/user';
import { LinearProgress } from '@mui/material';
import Error from '../UI/Error';

const ProtectedRoute = ({ component: Component, path }) => {
  const { loading, isAuthenticated, loginError, loginWithPopup, popupOpen } =
    useAuth0();
  const [requiresPopup, setRequiresPopup] = useState(false);

  useEffect(() => {
    if (!loading && !isAuthenticated && !loginError) {
      setRequiresPopup(true);
    }
  }, [loading, isAuthenticated, loginError, path]);

  useEffect(() => {
    if (requiresPopup) {
      setRequiresPopup(false);
      loginWithPopup();
    }
  }, [requiresPopup, loginWithPopup]);

  if (loginError) {
    return <Error error={loginError} />;
  }
  if (isAuthenticated) {
    return <Component />;
  }
  if (loading || popupOpen) {
    return <LinearProgress color="secondary" />;
  }
  return null;
};

export default ProtectedRoute;
