import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import config from '../config';

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);

let auth0Client = createAuth0Client({
  client_id: config.auth0.clientId,
  domain: config.auth0.domain,
  audience: 'https://serenity-csiro.example.com',
  max_age: 3600, // Allow refresh without popups for 1 hour since last
});

export const client = auth0Client;

export async function getToken() {
  const auth0 = await auth0Client;
  const isLoggedIn = await auth0.isAuthenticated();
  if (!isLoggedIn) {
    try {
      await auth0.loginWithPopup();
    } catch (e) {
      console.error(e);
    }
  }
  try {
    return await auth0.getTokenSilently();
  } catch (e) {}
  return new Promise((resolve) => {
    setTimeout(async () => {
      try {
        const token = await auth0.getTokenSilently();
        resolve(token);
        return;
      } catch (e) {}
    }, 250);
  });
}

export const Auth0Provider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [loginError, setLoginError] = useState(null);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await auth0Client;
      auth0Client = auth0FromHook;
      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    setLoading(true);
    setLoginError(null);
    let user;
    try {
      await auth0Client;
      const isAuthed = await auth0Client.isAuthenticated();
      user = await auth0Client.getUser();
      if (!isAuthed || !user) {
        await auth0Client.loginWithPopup({});
        user = await auth0Client.getUser();
      }
    } catch (error) {
      console.error(error);
      setLoginError('Could not complete login: ' + error.message);
    }
    if (user) {
      setUser(user);
      setIsAuthenticated(true);
    }
    setPopupOpen(false);
    setLoading(false);
    // Expire after an hour without loging out.
    setTimeout(() => {
      setUser(null);
      setIsAuthenticated(false);
    }, 35000000);
  };

  const logout = async () => {
    await auth0Client.logout({ returnTo: window.location.origin });
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginError,
        loginWithPopup,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
