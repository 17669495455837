import { createTheme } from '@mui/material/styles';
import { indigo, red } from '@mui/material/colors';

export const themeD = createTheme({
  palette: {
    primary: {
      light: '#49a2a6',
      main: '#007377',
      dark: '#00474b',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffa852',
      main: '#e87822',
      dark: '#b04a00',
      contrastText: '#000',
    },
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 4,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    type: 'light',
  },
  typography: {
    fontFamily: ['"Ubuntu"'].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: '0.01em',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: indigo['50'] + ' !important',
          },
        },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: indigo['50'] + ' !important',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: indigo['50'] + ' !important',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: indigo['100'] + ' !important',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '12px',
        },
      },
    },
  },
});

export default themeD;
