import makeStyles from '@mui/styles/makeStyles';

const drawerWidth = 240;

export default makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  link: { textDecoration: 'none', color: 'inherit' },
  subList: {
    marginLeft: '20px !important',
  },
}));
