import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
} from '@mui/material';
import Markdown from './Markdown';

const RoutedHelp = () => {
  const navigate = useNavigate();
  let params = useParams();
  // As route is /help/* then params['*'] contains remaining path after /help/
  const docPath = params['*'] ? params['*'] : 'index';

  return (
    <Container maxWidth="lg">
      <Card>
        <CardHeader
          title="User guide"
          titleTypographyProps={{ variant: 'h6' }}
        ></CardHeader>
        <CardContent>
          <Markdown docPath={docPath} />
        </CardContent>
        <CardActions>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Back
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};

export default RoutedHelp;
