import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Typography, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  p: {
    marginBottom: theme.spacing(3),
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      borderBottom: '1px solid',
    },
  },
  image: {
    verticalAlign: 'bottom', // for inline images
  },
}));

const Text = (props) => {
  const classes = useStyles();
  return <Typography className={classes.p}>{props.children}</Typography>;
};

const Heading = ({ level, children }) => {
  const classes = useStyles();
  return (
    <Typography variant={`h${level}`} className={classes.p}>
      {children}
    </Typography>
  );
};

const HelpLink = ({ href, children }) => {
  const classes = useStyles();
  const regex = new RegExp('.md$');
  if (href.match(regex)) {
    const route = href.replace(regex, '');
    return (
      <Link to={'/help/' + route} className={classes.link}>
        {children}
      </Link>
    );
  } else {
    return (
      <a href={href} target="_blank" rel="noreferrer" className={classes.link}>
        {children}
      </a>
    );
  }
};

const Image = ({ alt, src }) => {
  const classes = useStyles();
  const regex = new RegExp('^./');
  const newSrc = src.replace(regex, '/userdocs/');
  return <img alt={alt} src={newSrc} className={classes.image} />;
};

const List = ({ children, depth }) => {
  const classes = useStyles();
  if (depth === 0) {
    return (
      <Typography component="ul" className={classes.p}>
        {children}
      </Typography>
    );
  } else {
    return <ul>{children}</ul>;
  }
};

/* TODO : Ordered lists that don't start at 1. aren't currently supported */
const OrderedList = ({ children, depth }) => {
  const classes = useStyles();
  if (depth === 0) {
    return (
      <Typography component="ol" className={classes.p}>
        {children}
      </Typography>
    );
  } else {
    return <ol>{children}</ol>;
  }
};

const notFound = (docPath) => {
  return `Help document '${docPath}' not found.  
If a refresh doesn't help, please report this absence to system admins.`;
};

const Markdown = (props) => {
  const mdRenderers = {
    p: Text,
    h1: Heading,
    h2: Heading,
    h3: Heading,
    h4: Heading,
    h5: Heading,
    h6: Heading,
    a: HelpLink,
    img: Image,
    ul: List,
    ol: OrderedList,
  };
  const [source, setSource] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDoc = async () => {
      setLoading(true);
      try {
        const response = await fetch(`/userdocs/${props.docPath}.md`);
        const text = await response.text();
        if (text.startsWith('<!DOCTYPE html>')) {
          setSource(notFound(props.docPath));
        } else {
          setSource(text);
        }
      } catch (e) {
        console.log(e);
        setSource(notFound(props.docPath));
      }
      setLoading(false);
    };
    fetchDoc();
  }, [props.docPath]);

  if (loading) {
    return <LinearProgress color="secondary" />;
  }
  return <ReactMarkdown children={source} components={mdRenderers} />;
};

Markdown.propTypes = {
  docPath: PropTypes.string.isRequired,
};

export default Markdown;
