import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  Button,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import Markdown from './Markdown';

const PopupHelp = (props) => {
  const [dialogOpen, setDialogState] = useState(false);

  return <>
    <Tooltip id="help-tip" title="Help">
      <IconButton
        className={props.className}
        aria-label="Help"
        onClick={() => {
          setDialogState(true);
        }}
        size="large">
        <HelpIcon />
      </IconButton>
    </Tooltip>
    <Dialog
      open={dialogOpen}
      onClose={() => {
        setDialogState(false);
      }}
      scroll="paper"
    >
      <DialogContent>
        <Markdown docPath={props.docPath} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setDialogState(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  </>;
};

PopupHelp.propTypes = {
  docPath: PropTypes.string.isRequired,
};

export default PopupHelp;
