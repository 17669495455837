import React from 'react';
import PropTypes from 'prop-types';
import { useClearCache } from 'react-clear-cache';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@mui/material';
import ReloadIcon from '@mui/icons-material/Cached';
import makeStyles from '@mui/styles/makeStyles';
import { Markdown } from '../../UI/UserDocs';
const helpDocPath = 'versions';

const useStyles = makeStyles((theme) => ({
  versionsBox: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '40vh',
    overflowY: 'scroll',
    margin: theme.spacing(2, 1, 2, 2),
    paddingRight: theme.spacing(1),
  },
  noScroll: {
    overflowY: 'hidden',
  },
}));

const UpdateReload = ({ onDismiss }) => {
  const classes = useStyles();
  const { emptyCacheStorage } = useClearCache();

  return (
    <Dialog scroll="body" open={true}>
      <DialogContent className={classes.noScroll}>
        <Typography variant={'h5'} gutterBottom>
          A newer version has been released.
        </Typography>
        <Typography gutterBottom>
          A hard refresh may be required to clear cache and update. <br />
          Can try Ctrl+F5 (Windows/Unix) or Cmd+Shift+R (Mac) <br />
        </Typography>
        <Typography>
          If message remains after reload, you may dismiss this to proceed, with
          caution.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ReloadIcon />}
          onClick={(e) => {
            e.preventDefault();
            emptyCacheStorage();
          }}
        >
          Update & Reload
        </Button>
        <Button variant="outlined" color="primary" onClick={onDismiss}>
          Dismiss
        </Button>
      </DialogActions>
      <Box className={classes.versionsBox}>
        <Markdown docPath={helpDocPath} />
      </Box>
    </Dialog>
  );
};

UpdateReload.propTypes = {
  onDismiss: PropTypes.func.isRequired,
};

export default UpdateReload;
