import { merge, get } from 'lodash';

const stage = process.env.REACT_APP_ENV;

const configurations = {
  base: {
    env: 'local',
    serenityApi2: 'http://localhost:3003/',
    placid: 'http://localhost:3006/',
    collectid: 'http://localhost:3007/',
    terria: 'https://terriamap-dev.k8s.serenity-csiro.com/',
    auth0: {
      clientId: 'acDMqe3TlF95jTDutLywtF22Wv6NsRK4',
      domain: 'serenity.au.auth0.com',
    },
  },
  develop: {
    env: 'develop',
    serenityApi2: 'https://serenity-api.k8s.serenity-csiro.com/',
    placid: 'https://placid.k8s.serenity-csiro.com',
    collectid: 'https://collectid.k8s.serenity-csiro.com',
  },
  master: {
    env: 'prod',
    serenityApi2: 'https://api.serenity-csiro.com/',
    placid: 'https://placid-master.k8s.serenity-csiro.com',
    collectid: 'https://collectid-master.k8s.serenity-csiro.com',
    terria: 'https://terriamap.k8s.serenity-csiro.com/',
  },
};

export default merge(configurations.base, get(configurations, stage, {}));
