import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.jsx';
import { unregister } from './registerServiceWorker';
import './index.css';
import theme from './UI/theme';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Auth0Provider } from './services/user';
import { ClearCacheProvider } from 'react-clear-cache';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ClearCacheProvider duration={20000}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Auth0Provider>
            <App />
          </Auth0Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </ClearCacheProvider>,
);
unregister();
